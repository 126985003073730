body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-group label,
label {
  color: black;
}

ul li a,
ul li {
  color: black !important;
}

.logo {
  color: #315ae7 !important;
}

@media (min-width: 576px) {
  #expense_modal .modal-dialog,
  .modal_big {
    max-width: 800px;
    margin: 1.75rem auto;
  }
}

.lkjDVC {
  background: #fff;
  border: dashed 2px #e0e6fa !important;
  margin-bottom: 30px;
}

.dmajdp {
  justify-content: center !important;
}

.dmajdp .file-types {
  margin-left: 15px;
}

.lkjDVC svg {
  display: none;
}

.dmajdp > span {
  font-size: 1.2rem !important;
}

.custom-select {
  background-color: #fff;
  height: 47px;
}

.table-bordered td {
  border: 1px solid #a7a7a7;
}

table {
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .table-responsive {
    overflow-x: auto;
  }
}

.table-responsive {
  margin-bottom: 30px;
}

.table-responsive::-webkit-scrollbar {
  height: 5px;
}

#content {
  overflow-x: hidden;
}

.heading_text {
  font-weight: bold;
  color: #3b3f5c;
  font-size: 1.2rem;
}

#sidebar ul.menu-categories li.menu > a span:not(.badge) {
  font-weight: 600;
}

input[disabled],
select[disabled] {
  color: #000000 !important;
}

tfoot tr {
  font-weight: bold;
  color: #2262c6;
}

@media (min-width: 576px) {
  .salesReturnProductModal,
  .account_modal {
    max-width: 800px;
    margin: 1.75rem auto;
  }
}

/* switch toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #315ae7;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #315ae7;
}

input:checked + .slider:before {
  background-color: #fff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #315ae7;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#pos {
  min-height: 100vh;
  display: flex;
  font-family: "Montserrat", sans-serif;
}

#pos > div {
  flex: 1;
}

#pos .card img {
  height: 90px;
  width: 90px;
  object-fit: cover;
  margin: auto;
}

#pos .card {
  margin-bottom: 30px;
  border: 0;
  background: #e9eeff;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
  position: relative;
  overflow: hidden;
}

#pos .card > * {
  z-index: 1;
}

#pos .card::after {
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  background: linear-gradient(#e0e4ff, #f0f1ff);
  border-radius: 50%;
  top: -100%;
  left: -100%;
  z-index: 0;
  transition: 0.3s;
}

#pos .card:hover::after {
  top: 30%;
  left: 30%;
  transform: translate(-50%, -50%);
}

#pos .card:hover {
  background: #f3f6ff;
  box-shadow: 0 0 15px rgb(226, 226, 226);
}

#pos .products__container {
  padding: 15px;
  max-height: 600px;
  overflow: auto;
}

#pos .products__container::-webkit-scrollbar {
  width: 5px;
}

#pos .products__container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#pos .products__container::-webkit-scrollbar-thumb {
  background: #888;
}

#pos .products__container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#pos .leftSection__footer .jumbotron {
  background: #e1e5ff;
  margin-top: 15px;
  padding: 1rem;
}

#pos .left__section {
  position: relative;
}

#pos .leftSection__footer {
  position: absolute;
  bottom: -60px;
  left: 0;
  width: 100%;
  background: #e0e6fb;
}

#pos .leftSection__footer input {
  width: 80px;
  height: 40px;
}

#pos .selected__products {
  max-height: 50vh;
  overflow: auto;
}

#pos .selected__products::-webkit-scrollbar {
  width: 5px;
}

#pos .selected__products::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#pos .selected__products::-webkit-scrollbar-thumb {
  background: #888;
}

#pos .selected__products::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#pos input,
#pos select {
  background-color: #f6f7ff !important;
  border: 1px solid #e1e5ff !important;
}

@media only screen and (max-width: 1200px) {
  #pos {
    flex-direction: column-reverse;
  }

  #pos .card::after {
    width: 200px;
    height: 200px;
  }

  #pos .leftSection__footer {
    position: relative;
  }
}

#pos td div input {
  width: 50px;
  outline: none;
  background: transparent !important;
  border: none !important;
}

#pos td div i {
  font-size: 1rem;
  cursor: pointer;
  background: #e1e5ff;
  padding: 5px;
  border-radius: 3px;
}

#pos input[type="number"] {
  -moz-appearance: textfield;
}

#pos input::-webkit-outer-spin-button,
#pos input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.selected__products td input {
  max-width: 100px;
}
