.dashboard_card_icon {
  font-size: 2.5rem;
  color: #315ae7;
}

.dashboard_card_heading {
  color: #315ae7;
  font-size: 1.1rem;
  margin-top: 0.5rem;
}

.dashboard_card_value {
  font-size: 1.6rem;
  margin-top: 1.3rem;
  font-weight: 500;
}

/* chart style */
.bar_chart_container {
  background: white;
  padding: 50px 30px;
  margin: 2rem 0rem;
}

.chart_duration_btn button {
  background-color: #315ae7;
  border: none;
}

.chart_duration_btn button:hover,
.chart_duration_btn button:active,
.chart_duration_btn button:focus {
  background-color: #1b3fbd !important;
}

.chart_duration_btn button.active {
  background-color: #1b3fbd !important;
}
